import React,{useState, useRef, useEffect} from "react"
import FooterMenu from "./FooterMenu"
import FooterQuickMenu from "./FooterQuickLinksMenu"
import Twitcker from "./FeefoWidget.js"
import { useLocation } from "@reach/router";

function scrollToTop () {
  window.scrollTo({top: 0,
  behavior: 'smooth'})
}

const currentYear = new Date().getFullYear();

const Footer = props => {

  return (
    <>
    <footer className="content navy-background">
  <div className="eighty-spacer"></div>
  <div className="content-block">
     <div className="one-flex-container">

        <div className="third-flex-container">
                 <div className="footer-heading-holder">
                    Quick links
                 </div>
                 <div className="footer-menu-container">
                 <FooterQuickMenu />
                 </div>
           </div>
           <div className="third-flex-container pad-left-eighty smaller-footer-col">
                     <div className="footer-heading-holder">
                        Get in touch
                     </div>
                     <div className="footer-social-list-holder">
                        <ul>
                        <li>
                       <a href="https://www.linkedin.com/company/hampshire-trust-bank/?trk=biz-companies-cym" target="_blank" rel="noopener noreferrer">
                          <span className="linkedin-white-icon"></span>
                       </a>
                       </li>
                      <li>
                       <a href="https://twitter.com/htbplc" target="_blank" rel="noopener noreferrer">
                          <span className="twitter-white-icon"></span>
                       </a>
                       </li>
                      </ul>
                      </div>
              </div>
              <div className="third-flex-container footer-logos-col-fix">
               <div className="footer-logos-holder">
                  <ul>
                     <li>
                        <a href="https://www.fscs.org.uk/" target="_blank">
                           <img src="/htbcontent/themes/htb-personal/assets/img/fscs_protected_logo_wo.png" alt="FSCS Protected - Hampshire Trust Bank" title="FSCS Protected – Hampshire Trust Bank"/>
                        </a>
                     </li>
                     <li>
                        <a href="https://www.carbonfootprint.com/" target="_blank">
                           <img src="/htbcontent/themes/htb-personal/assets/img/2017-cfs-co2-neutral-org-wo.png" alt="Carbon Neutral Organisation - Hampshire Trust Bank" title="Carbon Neutral Organisation - Hampshire Trust Bank"/>
                        </a>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
         <div className="footer-legal-content-holder">
            &copy;{currentYear} Hampshire Trust Bank Plc is a company registered in England and Wales, registration number 01311315 and with registered office at 80 Fenchurch Street, London, EC3M 4BY. It is authorised by the Prudential Regulation Authority and regulated by the Prudential Regulation Authority and the Financial Conduct Authority. FRN 204601. HTB Leasing & Finance Ltd (formerly Wesleyan Bank Limited) is a company registered in England and Wales, registration number 2839202 and with registered office at 80 Fenchurch Street, London, EC3M 4BY. It is authorised and regulated by the Financial Conduct Authority. FRN 165116. Hampshire Trust Bank, HTB and <img className="alignnone wp-image-2751" src="/htbcontent/uploads/2020/01/Hants-logo-3-Copy-300x300.png" alt="HTB Logo" width="29" height="29"/> are registered trademarks of Hampshire Trust Bank Plc.
         </div>
         <div className="footer-secondary-menu-holder">
         <FooterMenu />
      </div>
     </div>
     <div className="eighty-spacer"></div>
     <div className="back-to-top-container" onClick={scrollToTop}>
      <img src="/htbcontent/themes/htb-personal/assets/img/back-to-top-button-mango.jpg" alt="Hampshire Trust Bank (HTB)" title="Hampshire Trust Bank (HTB)" width="60px" height="60px"/>
   </div>
      {/* <Twitcker/> */}
      {/* .widget-area */}
      {/* .site-info */}
  </footer>
  </>
)
}

export default Footer
